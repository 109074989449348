import React, { useState, useEffect, useRef } from "react";
import { openSignRequest, endpoint } from "./lib";
import { useParams } from "react-router";

import { country_codes } from "./countries";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

const Register = () => {
  let { bearer } = useParams();
  let { account } = useParams();
  let { accountName } = useParams();

  let immutableness = useRef("");

  const [msg, setMSG] = useState("");

  const [verif, setVerif] = useState(false);
  const [verifCode, setVerifCode] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);

  const [name, setName] = useState("");
  const [to, setTo] = useState("");
  const [code, setCode] = useState(localStorage.getItem("code") || "+31");

  const handleCountryChange = (event) => {
    console.log(event.target.value);
    localStorage.setItem("code", event.target.value);
    setCode(event.target.value);
    setBtnStatus(false);
  };

  const handleTo = (e) => {
    const phone = e.target.value;
    if (phone.length === 0 || phone.length < 9 || phone.length > 13) {
      setMSG("Phone no not within the range");
      return;
    } else {
      setBtnStatus(false);
      setTo(e.target.value);
      setMSG("");
    }
  };
  const handleVerif = (e) => {
    if (e.target.value === "") {
      setMSG("Enter Verification Code");
      return;
    }
    if (e.target.value.length === 6) {
      setVerifCode(e.target.value);
    }
  };
  const handleName = (e) => {
    const name = e.target.value.length;
    if (name === 0 || name < 4 || name > 12 || e.target.value === null) {
      setMSG("Name has to be 4 to 12 characters in length.");
      return;
    } else {
      setName(e.target.value);
      setMSG("");
    }
  };

  const getCode = async (event) => {
    if (name.length === 0 || name.length < 4 || name.length > 12) {
      setMSG("Name: Not within the range");
      return;
    }

    if (to.length === "" || to.length < 9 || to.length > 13) {
      setMSG("Phone no not within the range. " + to.length);
      return;
    }

    setBtnStatus(true);
    const user = await fetch(
      `${endpoint}?flag=3&bearer=` +
        bearer +
        "&account=" +
        account +
        "&name=" +
        name +
        "&to=" +
        code +
        "-" +
        to
    );
    const response = JSON.parse(await user.json());

    immutableness.current = response.uuid;
    openSignRequest(response.uuid);
  };

  const registration = (event) => {
    const result = JSON.parse(event.data);
    if (result.reason === "SIGNED") {
      setMSG("Please wait ...");
      (async () => {
        const tx = await fetch(
          `${endpoint}?flag=4&uuid=` + immutableness.current
        );
        const response = await tx.json();

        if (response === "1") {
          setVerif(true);
          setBtnStatus(false);
          setMSG("Verification Code Sent To Your Number.");
        } else if (response === "0") {
          setMSG("Please try after 24 hours from now.");
        } else if (response === "2") {
          setMSG("Failed: Please check country code and phone number.");
        } else {
          //implement try-again button, and pass the same uuid to the endpoint.
        }
      })();
    } else if (result.reason === "DECLINED") {
      setVerif(false);
      setBtnStatus(false);
    }
  };

  const submit = async () => {
    setBtnStatus(true);
    const tx = await fetch(
      `${endpoint}?flag=6&account=` + account + "&rand=" + verifCode
    );
    const response = await tx.json();
    if (response === "0") {
      setBtnStatus(false);
      setMSG("Wrong Code Entered");
    } else if (response === "3") {
      setBtnStatus(true);
      setMSG("Please restart the xApp");
    } else if (response === "5") {
      setBtnStatus(true);
      setMSG("Too many attempts. Try again after 24hrs.");
    } else if (response === "1") {
      setBtnStatus(true);
      setMSG("Registered. Please restart xApp.");
    }
  };

  useEffect(() => {
    setName(accountName);

    if (typeof window.addEventListener === "function") {
      window.addEventListener("message", registration);
    }
    if (typeof document.addEventListener === "function") {
      document.addEventListener("message", registration);
    }

    return () => {
      if (typeof window.addEventListener === "function") {
        window.removeEventListener("message", registration);
      }
      if (typeof document.addEventListener === "function") {
        document.removeEventListener("message", registration);
      }
    };
  }, [accountName]);

  return (
    <div style={{ padding: "10px" }}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Stack spacing={4} id="stage">
            <span>
              <InputLabel id="demo-simple-select-label">
                Country Code
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="simple-select"
                value={code}
                label="Country Code"
                onChange={handleCountryChange}
                style={{ width: "100%" }}
                disabled={verif}
              >
                {country_codes.map((c, index) => {
                  return (
                    <MenuItem key={index} value={c.code}>
                      [{c.code}] {c.country}
                    </MenuItem>
                  );
                })}
              </Select>
            </span>
            <TextField
              id="phone"
              label="Your Phone Number"
              placeholder="Register your 10-digit Phone Number"
              onBlur={handleTo}
              type="number"
              disabled={verif}
              InputProps={{
                inputProps: {
                  maxLength: 13,
                  minLength: 8,
                },
              }}
            />
            <TextField
              id="name"
              defaultValue={accountName === null ? "" : accountName}
              label="Your Name"
              placeholder="Name to include in SMS"
              onBlur={handleName}
              type="text"
              disabled={verif}
              InputProps={{
                inputProps: {
                  maxlength: 13,
                  minlength: 3,
                },
              }}
            />
            <TextField
              id="account"
              defaultValue={account}
              label="Your SMS Account"
              placeholder="Use this account to send SMS"
              type="text"
              disabled={true}
            />
            {verif !== false ? (
              <TextField
                id="code"
                label="Verification Code"
                placeholder="Enter the code you received on your phone number"
                onChange={handleVerif}
                type="number"
                disabled={!verif}
              />
            ) : (
              <></>
            )}
            {verif === false ? (
              <Button
                variant="contained"
                onClick={getCode}
                disabled={btnStatus}
              >
                Get Verification Code
              </Button>
            ) : (
              <Button variant="contained" onClick={submit} disabled={btnStatus}>
                Register
              </Button>
            )}
          </Stack>
        </FormControl>
        <Divider />
        <div
          style={{
            position: "absolute",
            top: verif === false ? "70%" : "82%",
            width: "100%",
            fontWeight: "bold",
            wordWrap: "break-word",
            overflowX: "hidden",
          }}
        >
          <div style={{ textAlign: "center" }}> {msg}</div>
        </div>
        {msg === "" ? (
          <div style={{ marginTop: "23%", marginLeft: "8%" }}>
            <strong>1. Registration Cost: </strong>0.5 XRP.
            <br />
            <strong>2. Sending SMS: </strong>0.1 XRP per SMS.
            <br />
            <strong>3.</strong> No refunds on failed SMS.
            <br />
            <strong>4.</strong> Do not send offensive SMS.
            <br />
            <strong>5.</strong> Switch to same r-address to use SMS xApp.
          </div>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};

export default Register;
