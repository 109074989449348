import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";

import SMS_APP from "./main";
import Register from "./register";
import SMS from "./sms";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SMS_APP />} />
        <Route
          path="register/:bearer/:account/:accountName"
          element={<Register />}
        />
        <Route path="sms/:bearer/:account" element={<SMS />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
