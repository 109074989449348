import React, { useState, useEffect, useRef } from "react";
import { country_codes } from "./countries";
import { useParams } from "react-router";
import { openSignRequest, endpoint } from "./lib";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const SMS = () => {
  let { bearer } = useParams();
  let { account } = useParams();

  // let { accountName } = useParams();
  let immutableness = useRef("");
  const [code, setCode] = useState(localStorage.getItem("code") || "+31");
  const [msg, setMSG] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  const [to, setTo] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [text, setText] = useState("");

  const handleCountryChange = (event) => {
    localStorage.setItem("code", event.target.value);
    setCode(event.target.value);
  };

  const handleTo = (e) => {
    const phone = e.target.value;
    if (phone.length === 0 || phone.length < 9 || phone.length > 13) {
      setMSG("Phone no not within the range");
      return;
    } else {
      setTo(e.target.value);
      setMSG("");
    }
  };

  const handleMessage = (e) => {
    if (e.target.value.length > 110) {
      setMSG("Error In Text Message");
      setBtnStatus(true);
      return;
    }

    if (e.target.value !== "") {
      const scan_sms = e.target.value.search(
        /[^A-Za-z0-9 \r\n@£$¥!"#$%&amp;'()*+,_./:;&lt;=&gt;?^{}\\[~\]]/g
      );
      if (scan_sms !== -1) {
        setMSG(
          `Please remove the character ${e.target.value[scan_sms]} at position ${scan_sms}.`
        );
        setBtnStatus(true);
      } else {
        setBtnStatus(false);
        setText(e.target.value);
        setMSG("");
      }
      setWordCount(e.target.value.length);
    }
  };

  const send = async () => {
    setBtnStatus(true);
    if (to.length === 0 || to.length < 9 || to.length > 13) {
      setBtnStatus(false);
      setMSG("Phone no not within the range. " + to.length);
      return;
    }
    if (text.length === 0 || text.length > 110) {
      setBtnStatus(false);
      setMSG("Error In Text Message");
      return;
    }

    const scan_sms = text.search(
      /[^A-Za-z0-9 \r\n@£$¥!"#$%&amp;'()*+,_./:;&lt;=&gt;?^{}\\[~\]]/g
    );
    if (scan_sms !== -1) {
      setMSG(
        `Please remove the character ${text[scan_sms]} at position ${scan_sms}.`
      );
      setBtnStatus(true);
      return;
    }

    const user = await fetch(
      `${endpoint}?flag=7&bearer=` +
        bearer +
        "&account=" +
        account +
        "&name=" +
        text +
        "&to=" +
        code +
        "-" +
        to
    );
    const response = JSON.parse(await user.json());

    immutableness.current = response.uuid;
    openSignRequest(response.uuid);
  };

  const sendingMessage = async (event) => {
    const result = JSON.parse(event.data);
    if (result.reason === "SIGNED") {
      setMSG("Please wait ...");
      const tx = await fetch(
        `${endpoint}?flag=4&uuid=` + immutableness.current
      );
      const response = await tx.json();

      if (response === 1 || response === "1") {
        setMSG("SMS Sent");
        setBtnStatus(false);
      } else if (response === "2") {
        setMSG("Failed: Please check country code and phone number.");
        setBtnStatus(false);
      } else if (response === 5) {
        setMSG("Please restart xApp.");
      } else if (response === 0) {
        //implement try-again button, and pass the same uuid to the endpoint.
        setMSG("Something went wrong. Sorry.");
      } else {
        setMSG(response);
        setBtnStatus(false);
      }
    } else if (result.reason === "DECLINED") {
      setBtnStatus(false);
    }
  };

  useEffect(() => {
    if (typeof window.addEventListener === "function") {
      window.addEventListener("message", sendingMessage);
    }
    if (typeof document.addEventListener === "function") {
      document.addEventListener("message", sendingMessage);
    }

    return () => {
      if (typeof window.addEventListener === "function") {
        window.removeEventListener("message", sendingMessage);
      }
      if (typeof document.addEventListener === "function") {
        document.removeEventListener("message", sendingMessage);
      }
    };
  }, []);
  return (
    <div style={{ padding: "10px" }}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Stack spacing={4}>
            <span>
              <InputLabel id="demo-simple-select-label">
                Country Code
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={code}
                label="Country Code"
                onChange={handleCountryChange}
                style={{ width: "100%" }}
              >
                {country_codes.map((c, index) => {
                  return (
                    <MenuItem key={index} value={c.code}>
                      [{c.code}] {c.country}
                    </MenuItem>
                  );
                })}
              </Select>
            </span>
            <TextField
              id="outlined-name"
              label="To"
              placeholder="10-digit Phone Number"
              onChange={handleTo}
            />
            <TextField
              id="outlined-name"
              label={`SMS Text ${wordCount}/110`}
              placeholder="Enter 110 Character Message to Send"
              onChange={handleMessage}
              multiline={true}
              rows="5"
              error={wordCount < 110 ? false : true}
            />
            <Button variant="contained" onClick={send} disabled={btnStatus}>
              Send
            </Button>
          </Stack>
        </FormControl>
        <div
          style={{
            position: "absolute",
            top: "70%",
            width: "100%",
            fontWeight: "bold",
            wordWrap: "break-word",
            overflowX: "hidden",
          }}
        >
          <div style={{ textAlign: "center" }}> {msg}</div>
        </div>
        {msg === "" ? (
          <div style={{ marginTop: "23%", marginLeft: "8%" }}>
            <strong>1. Sending SMS: </strong>0.1 XRP per SMS.
            <br />
            <strong>2.</strong> No refunds on failed SMS.
            <br />
            <strong>3.</strong> Do not send offensive SMS.
          </div>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};
export default SMS;
