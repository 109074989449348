export const country_codes = [
  {
    country: "Afghanistan",
    code: "+93",
    ISO: "AF / AFG",
  },
  {
    country: "Albania",
    code: "+355",
    ISO: "AL / ALB",
  },
  {
    country: "Algeria",
    code: "+213",
    ISO: "DZ / DZA",
  },
  {
    country: "American Samoa",
    code: "+1684",
    ISO: "AS / ASM",
  },
  {
    country: "Andorra",
    code: "+376",
    ISO: "AD / AND",
  },
  {
    country: "Angola",
    code: "+244",
    ISO: "AO / AGO",
  },
  {
    country: "Anguilla",
    code: "+1264",
    ISO: "AI / AIA",
  },
  {
    country: "Antarctica",
    code: "+672",
    ISO: "AQ / ATA",
  },
  {
    country: "Antigua and Barbuda",
    code: "+1268",
    ISO: "AG / ATG",
  },
  {
    country: "Argentina",
    code: "+54",
    ISO: "AR / ARG",
  },
  {
    country: "Armenia",
    code: "+374",
    ISO: "AM / ARM",
  },
  {
    country: "Aruba",
    code: "+297",
    ISO: "AW / ABW",
  },
  {
    country: "Australia",
    code: "+61",
    ISO: "AU / AUS",
  },
  {
    country: "Austria",
    code: "+43",
    ISO: "AT / AUT",
  },
  {
    country: "Azerbaijan",
    code: "+994",
    ISO: "AZ / AZE",
  },
  {
    country: "Bahamas",
    code: "+1242",
    ISO: "BS / BHS",
  },
  {
    country: "Bahrain",
    code: "+973",
    ISO: "BH / BHR",
  },
  {
    country: "Bangladesh",
    code: "+880",
    ISO: "BD / BGD",
  },
  {
    country: "Barbados",
    code: "+1246",
    ISO: "BB / BRB",
  },
  {
    country: "Belarus",
    code: "+375",
    ISO: "BY / BLR",
  },
  {
    country: "Belgium",
    code: "+32",
    ISO: "BE / BEL",
  },
  {
    country: "Belize",
    code: "+501",
    ISO: "BZ / BLZ",
  },
  {
    country: "Benin",
    code: "+229",
    ISO: "BJ / BEN",
  },
  {
    country: "Bermuda",
    code: "+1441",
    ISO: "BM / BMU",
  },
  {
    country: "Bhutan",
    code: "+975",
    ISO: "BT / BTN",
  },
  {
    country: "Bolivia",
    code: "+591",
    ISO: "BO / BOL",
  },
  {
    country: "Bosnia and Herzegovina",
    code: "+387",
    ISO: "BA / BIH",
  },
  {
    country: "Botswana",
    code: "+267",
    ISO: "BW / BWA",
  },
  {
    country: "Brazil",
    code: "+55",
    ISO: "BR / BRA",
  },
  {
    country: "British Indian Ocean Territory",
    code: "+246",
    ISO: "IO / IOT",
  },
  {
    country: "British Virgin Islands",
    code: "+1284",
    ISO: "VG / VGB",
  },
  {
    country: "Brunei",
    code: "+673",
    ISO: "BN / BRN",
  },
  {
    country: "Bulgaria",
    code: "+359",
    ISO: "BG / BGR",
  },
  {
    country: "Burkina Faso",
    code: "+226",
    ISO: "BF / BFA",
  },
  {
    country: "Burundi",
    code: "+257",
    ISO: "BI / BDI",
  },
  {
    country: "Cambodia",
    code: "+855",
    ISO: "KH / KHM",
  },
  {
    country: "Cameroon",
    code: "+237",
    ISO: "CM / CMR",
  },
  {
    country: "Canada",
    code: "+1",
    ISO: "CA / CAN",
  },
  {
    country: "Cape Verde",
    code: "+238",
    ISO: "CV / CPV",
  },
  {
    country: "Cayman Islands",
    code: "+1345",
    ISO: "KY / CYM",
  },
  {
    country: "Central African Republic",
    code: "+236",
    ISO: "CF / CAF",
  },
  {
    country: "Chad",
    code: "+235",
    ISO: "TD / TCD",
  },
  {
    country: "Chile",
    code: "+56",
    ISO: "CL / CHL",
  },
  {
    country: "China",
    code: "+86",
    ISO: "CN / CHN",
  },
  {
    country: "Christmas Island",
    code: "+61",
    ISO: "CX / CXR",
  },
  {
    country: "Cocos Islands",
    code: "+61",
    ISO: "CC / CCK",
  },
  {
    country: "Colombia",
    code: "+57",
    ISO: "CO / COL",
  },
  {
    country: "Comoros",
    code: "+269",
    ISO: "KM / COM",
  },
  {
    country: "Cook Islands",
    code: "+682",
    ISO: "CK / COK",
  },
  {
    country: "Costa Rica",
    code: "+506",
    ISO: "CR / CRI",
  },
  {
    country: "Croatia",
    code: "+385",
    ISO: "HR / HRV",
  },
  {
    country: "Cuba",
    code: "+53",
    ISO: "CU / CUB",
  },
  {
    country: "Curacao",
    code: "+599",
    ISO: "CW / CUW",
  },
  {
    country: "Cyprus",
    code: "+357",
    ISO: "CY / CYP",
  },
  {
    country: "Czech Republic",
    code: "+420",
    ISO: "CZ / CZE",
  },
  {
    country: "Democratic Republic of the Congo",
    code: "+243",
    ISO: "CD / COD",
  },
  {
    country: "Denmark",
    code: "+45",
    ISO: "DK / DNK",
  },
  {
    country: "Djibouti",
    code: "+253",
    ISO: "DJ / DJI",
  },
  {
    country: "Dominica",
    code: "+1767",
    ISO: "DM / DMA",
  },
  {
    country: "Dominican Republic",
    code: "+1809",
    ISO: "DO / DOM",
  },
  {
    country: "Dominican Republic",
    code: "+1829",
    ISO: "DO / DOM",
  },
  {
    country: "Dominican Republic",
    code: "+1849",
    ISO: "DO / DOM",
  },
  {
    country: "East Timor",
    code: "+670",
    ISO: "TL / TLS",
  },
  {
    country: "Ecuador",
    code: "+593",
    ISO: "EC / ECU",
  },
  {
    country: "Egypt",
    code: "+20",
    ISO: "EG / EGY",
  },
  {
    country: "El Salvador",
    code: "+503",
    ISO: "SV / SLV",
  },
  {
    country: "Equatorial Guinea",
    code: "+240",
    ISO: "GQ / GNQ",
  },
  {
    country: "Eritrea",
    code: "+291",
    ISO: "ER / ERI",
  },
  {
    country: "Estonia",
    code: "+372",
    ISO: "EE / EST",
  },
  {
    country: "Ethiopia",
    code: "+251",
    ISO: "ET / ETH",
  },
  {
    country: "Falkland Islands",
    code: "+500",
    ISO: "FK / FLK",
  },
  {
    country: "Faroe Islands",
    code: "+298",
    ISO: "FO / FRO",
  },
  {
    country: "Fiji",
    code: "+679",
    ISO: "FJ / FJI",
  },
  {
    country: "Finland",
    code: "+358",
    ISO: "FI / FIN",
  },
  {
    country: "France",
    code: "+33",
    ISO: "FR / FRA",
  },
  {
    country: "French Polynesia",
    code: "+689",
    ISO: "PF / PYF",
  },
  {
    country: "Gabon",
    code: "+241",
    ISO: "GA / GAB",
  },
  {
    country: "Gambia",
    code: "+220",
    ISO: "GM / GMB",
  },
  {
    country: "Georgia",
    code: "+995",
    ISO: "GE / GEO",
  },
  {
    country: "Germany",
    code: "+49",
    ISO: "DE / DEU",
  },
  {
    country: "Ghana",
    code: "+233",
    ISO: "GH / GHA",
  },
  {
    country: "Gibraltar",
    code: "+350",
    ISO: "GI / GIB",
  },
  {
    country: "Greece",
    code: "+30",
    ISO: "GR / GRC",
  },
  {
    country: "Greenland",
    code: "+299",
    ISO: "GL / GRL",
  },
  {
    country: "Grenada",
    code: "+1473",
    ISO: "GD / GRD",
  },
  {
    country: "Guam",
    code: "+1671",
    ISO: "GU / GUM",
  },
  {
    country: "Guatemala",
    code: "+502",
    ISO: "GT / GTM",
  },
  {
    country: "Guernsey",
    code: "+441481",
    ISO: "GG / GGY",
  },
  {
    country: "Guinea",
    code: "+224",
    ISO: "GN / GIN",
  },
  {
    country: "Guinea-Bissau",
    code: "+245",
    ISO: "GW / GNB",
  },
  {
    country: "Guyana",
    code: "+592",
    ISO: "GY / GUY",
  },
  {
    country: "Haiti",
    code: "+509",
    ISO: "HT / HTI",
  },
  {
    country: "Honduras",
    code: "+504",
    ISO: "HN / HND",
  },
  {
    country: "Hong Kong",
    code: "+852",
    ISO: "HK / HKG",
  },
  {
    country: "Hungary",
    code: "+36",
    ISO: "HU / HUN",
  },
  {
    country: "Iceland",
    code: "+354",
    ISO: "IS / ISL",
  },
  {
    country: "India",
    code: "+91",
    ISO: "IN / IND",
  },
  {
    country: "Indonesia",
    code: "+62",
    ISO: "ID / IDN",
  },
  {
    country: "Iran",
    code: "+98",
    ISO: "IR / IRN",
  },
  {
    country: "Iraq",
    code: "+964",
    ISO: "IQ / IRQ",
  },
  {
    country: "Ireland",
    code: "+353",
    ISO: "IE / IRL",
  },
  {
    country: "Isle of Man",
    code: "+441624",
    ISO: "IM / IMN",
  },
  {
    country: "Israel",
    code: "+972",
    ISO: "IL / ISR",
  },
  {
    country: "Italy",
    code: "+39",
    ISO: "IT / ITA",
  },
  {
    country: "Ivory Coast",
    code: "+225",
    ISO: "CI / CIV",
  },
  {
    country: "Jamaica",
    code: "+1876",
    ISO: "JM / JAM",
  },
  {
    country: "Japan",
    code: "+81",
    ISO: "JP / JPN",
  },
  {
    country: "Jersey",
    code: "+441534",
    ISO: "JE / JEY",
  },
  {
    country: "Jordan",
    code: "+962",
    ISO: "JO / JOR",
  },
  {
    country: "Kazakhstan",
    code: "+7",
    ISO: "KZ / KAZ",
  },
  {
    country: "Kenya",
    code: "+254",
    ISO: "KE / KEN",
  },
  {
    country: "Kiribati",
    code: "+686",
    ISO: "KI / KIR",
  },
  {
    country: "Kosovo",
    code: "+383",
    ISO: "XK / XKX",
  },
  {
    country: "Kuwait",
    code: "+965",
    ISO: "KW / KWT",
  },
  {
    country: "Kyrgyzstan",
    code: "+996",
    ISO: "KG / KGZ",
  },
  {
    country: "Laos",
    code: "+856",
    ISO: "LA / LAO",
  },
  {
    country: "Latvia",
    code: "+371",
    ISO: "LV / LVA",
  },
  {
    country: "Lebanon",
    code: "+961",
    ISO: "LB / LBN",
  },
  {
    country: "Lesotho",
    code: "+266",
    ISO: "LS / LSO",
  },
  {
    country: "Liberia",
    code: "+231",
    ISO: "LR / LBR",
  },
  {
    country: "Libya",
    code: "+218",
    ISO: "LY / LBY",
  },
  {
    country: "Liechtenstein",
    code: "+423",
    ISO: "LI / LIE",
  },
  {
    country: "Lithuania",
    code: "+370",
    ISO: "LT / LTU",
  },
  {
    country: "Luxembourg",
    code: "+352",
    ISO: "LU / LUX",
  },
  {
    country: "Macau",
    code: "+853",
    ISO: "MO / MAC",
  },
  {
    country: "Macedonia",
    code: "+389",
    ISO: "MK / MKD",
  },
  {
    country: "Madagascar",
    code: "+261",
    ISO: "MG / MDG",
  },
  {
    country: "Malawi",
    code: "+265",
    ISO: "MW / MWI",
  },
  {
    country: "Malaysia",
    code: "+60",
    ISO: "MY / MYS",
  },
  {
    country: "Maldives",
    code: "+960",
    ISO: "MV / MDV",
  },
  {
    country: "Mali",
    code: "+223",
    ISO: "ML / MLI",
  },
  {
    country: "Malta",
    code: "+356",
    ISO: "MT / MLT",
  },
  {
    country: "Marshall Islands",
    code: "+692",
    ISO: "MH / MHL",
  },
  {
    country: "Mauritania",
    code: "+222",
    ISO: "MR / MRT",
  },
  {
    country: "Mauritius",
    code: "+230",
    ISO: "MU / MUS",
  },
  {
    country: "Mayotte",
    code: "+262",
    ISO: "YT / MYT",
  },
  {
    country: "Mexico",
    code: "+52",
    ISO: "MX / MEX",
  },
  {
    country: "Micronesia",
    code: "+691",
    ISO: "FM / FSM",
  },
  {
    country: "Moldova",
    code: "+373",
    ISO: "MD / MDA",
  },
  {
    country: "Monaco",
    code: "+377",
    ISO: "MC / MCO",
  },
  {
    country: "Mongolia",
    code: "+976",
    ISO: "MN / MNG",
  },
  {
    country: "Montenegro",
    code: "+382",
    ISO: "ME / MNE",
  },
  {
    country: "Montserrat",
    code: "+1664",
    ISO: "MS / MSR",
  },
  {
    country: "Morocco",
    code: "+212",
    ISO: "MA / MAR",
  },
  {
    country: "Mozambique",
    code: "+258",
    ISO: "MZ / MOZ",
  },
  {
    country: "Myanmar",
    code: "+95",
    ISO: "MM / MMR",
  },
  {
    country: "Namibia",
    code: "+264",
    ISO: "NA / NAM",
  },
  {
    country: "Nauru",
    code: "+674",
    ISO: "NR / NRU",
  },
  {
    country: "Nepal",
    code: "+977",
    ISO: "NP / NPL",
  },
  {
    country: "Netherlands",
    code: "+31",
    ISO: "NL / NLD",
  },
  {
    country: "Netherlands Antilles",
    code: "+599",
    ISO: "AN / ANT",
  },
  {
    country: "New Caledonia",
    code: "+687",
    ISO: "NC / NCL",
  },
  {
    country: "New Zealand",
    code: "+64",
    ISO: "NZ / NZL",
  },
  {
    country: "Nicaragua",
    code: "+505",
    ISO: "NI / NIC",
  },
  {
    country: "Niger",
    code: "+227",
    ISO: "NE / NER",
  },
  {
    country: "Nigeria",
    code: "+234",
    ISO: "NG / NGA",
  },
  {
    country: "Niue",
    code: "+683",
    ISO: "NU / NIU",
  },
  {
    country: "North Korea",
    code: "+850",
    ISO: "KP / PRK",
  },
  {
    country: "Northern Mariana Islands",
    code: "+1670",
    ISO: "MP / MNP",
  },
  {
    country: "Norway",
    code: "+47",
    ISO: "NO / NOR",
  },
  {
    country: "Oman",
    code: "+968",
    ISO: "OM / OMN",
  },
  {
    country: "Pakistan",
    code: "+92",
    ISO: "PK / PAK",
  },
  {
    country: "Palau",
    code: "+680",
    ISO: "PW / PLW",
  },
  {
    country: "Palestine",
    code: "+970",
    ISO: "PS / PSE",
  },
  {
    country: "Panama",
    code: "+507",
    ISO: "PA / PAN",
  },
  {
    country: "Papua New Guinea",
    code: "+675",
    ISO: "PG / PNG",
  },
  {
    country: "Paraguay",
    code: "+595",
    ISO: "PY / PRY",
  },
  {
    country: "Peru",
    code: "+51",
    ISO: "PE / PER",
  },
  {
    country: "Philippines",
    code: "+63",
    ISO: "PH / PHL",
  },
  {
    country: "Pitcairn",
    code: "+64",
    ISO: "PN / PCN",
  },
  {
    country: "Poland",
    code: "+48",
    ISO: "PL / POL",
  },
  {
    country: "Portugal",
    code: "+351",
    ISO: "PT / PRT",
  },
  {
    country: "Puerto Rico",
    code: "+1787",
    ISO: "PR / PRI",
  },
  {
    country: "Puerto Rico",
    code: "+1939",
    ISO: "PR / PRI",
  },
  {
    country: "Qatar",
    code: "+974",
    ISO: "QA / QAT",
  },
  {
    country: "Republic of the Congo",
    code: "+242",
    ISO: "CG / COG",
  },
  {
    country: "Reunion",
    code: "+262",
    ISO: "RE / REU",
  },
  {
    country: "Romania",
    code: "+40",
    ISO: "RO / ROU",
  },
  {
    country: "Russia",
    code: "+7",
    ISO: "RU / RUS",
  },
  {
    country: "Rwanda",
    code: "+250",
    ISO: "RW / RWA",
  },
  {
    country: "Saint Barthelemy",
    code: "+590",
    ISO: "BL / BLM",
  },
  {
    country: "Saint Helena",
    code: "+290",
    ISO: "SH / SHN",
  },
  {
    country: "Saint Kitts and Nevis",
    code: "+1869",
    ISO: "KN / KNA",
  },
  {
    country: "Saint Lucia",
    code: "+1758",
    ISO: "LC / LCA",
  },
  {
    country: "Saint Martin",
    code: "+590",
    ISO: "MF / MAF",
  },
  {
    country: "Saint Pierre and Miquelon",
    code: "+508",
    ISO: "PM / SPM",
  },
  {
    country: "Saint Vincent and the Grenadines",
    code: "+1784",
    ISO: "VC / VCT",
  },
  {
    country: "Samoa",
    code: "+685",
    ISO: "WS / WSM",
  },
  {
    country: "San Marino",
    code: "+378",
    ISO: "SM / SMR",
  },
  {
    country: "Sao Tome and Principe",
    code: "+239",
    ISO: "ST / STP",
  },
  {
    country: "Saudi Arabia",
    code: "+966",
    ISO: "SA / SAU",
  },
  {
    country: "Senegal",
    code: "+221",
    ISO: "SN / SEN",
  },
  {
    country: "Serbia",
    code: "+381",
    ISO: "RS / SRB",
  },
  {
    country: "Seychelles",
    code: "+248",
    ISO: "SC / SYC",
  },
  {
    country: "Sierra Leone",
    code: "+232",
    ISO: "SL / SLE",
  },
  {
    country: "Singapore",
    code: "+65",
    ISO: "SG / SGP",
  },
  {
    country: "Sint Maarten",
    code: "+1721",
    ISO: "SX / SXM",
  },
  {
    country: "Slovakia",
    code: "+421",
    ISO: "SK / SVK",
  },
  {
    country: "Slovenia",
    code: "+386",
    ISO: "SI / SVN",
  },
  {
    country: "Solomon Islands",
    code: "+677",
    ISO: "SB / SLB",
  },
  {
    country: "Somalia",
    code: "+252",
    ISO: "SO / SOM",
  },
  {
    country: "South Africa",
    code: "+27",
    ISO: "ZA / ZAF",
  },
  {
    country: "South Korea",
    code: "+82",
    ISO: "KR / KOR",
  },
  {
    country: "South Sudan",
    code: "+211",
    ISO: "SS / SSD",
  },
  {
    country: "Spain",
    code: "+34",
    ISO: "ES / ESP",
  },
  {
    country: "Sri Lanka",
    code: "+94",
    ISO: "LK / LKA",
  },
  {
    country: "Sudan",
    code: "+249",
    ISO: "SD / SDN",
  },
  {
    country: "Suriname",
    code: "+597",
    ISO: "SR / SUR",
  },
  {
    country: "Svalbard and Jan Mayen",
    code: "+47",
    ISO: "SJ / SJM",
  },
  {
    country: "Swaziland",
    code: "+268",
    ISO: "SZ / SWZ",
  },
  {
    country: "Sweden",
    code: "+46",
    ISO: "SE / SWE",
  },
  {
    country: "Switzerland",
    code: "+41",
    ISO: "CH / CHE",
  },
  {
    country: "Syria",
    code: "+963",
    ISO: "SY / SYR",
  },
  {
    country: "Taiwan",
    code: "+886",
    ISO: "TW / TWN",
  },
  {
    country: "Tajikistan",
    code: "+992",
    ISO: "TJ / TJK",
  },
  {
    country: "Tanzania",
    code: "+255",
    ISO: "TZ / TZA",
  },
  {
    country: "Thailand",
    code: "+66",
    ISO: "TH / THA",
  },
  {
    country: "Togo",
    code: "+228",
    ISO: "TG / TGO",
  },
  {
    country: "Tokelau",
    code: "+690",
    ISO: "TK / TKL",
  },
  {
    country: "Tonga",
    code: "+676",
    ISO: "TO / TON",
  },
  {
    country: "Trinidad and Tobago",
    code: "+1868",
    ISO: "TT / TTO",
  },
  {
    country: "Tunisia",
    code: "+216",
    ISO: "TN / TUN",
  },
  {
    country: "Turkey",
    code: "+90",
    ISO: "TR / TUR",
  },
  {
    country: "Turkmenistan",
    code: "+993",
    ISO: "TM / TKM",
  },
  {
    country: "Turks and Caicos Islands",
    code: "+1649",
    ISO: "TC / TCA",
  },
  {
    country: "Tuvalu",
    code: "+688",
    ISO: "TV / TUV",
  },
  {
    country: "U.S. Virgin Islands",
    code: "+1340",
    ISO: "VI / VIR",
  },
  {
    country: "Uganda",
    code: "+256",
    ISO: "UG / UGA",
  },
  {
    country: "Ukraine",
    code: "+380",
    ISO: "UA / UKR",
  },
  {
    country: "United Arab Emirates",
    code: "+971",
    ISO: "AE / ARE",
  },
  {
    country: "United Kingdom",
    code: "+44",
    ISO: "GB / GBR",
  },
  {
    country: "United States",
    code: "+1",
    ISO: "US / USA",
  },
  {
    country: "Uruguay",
    code: "+598",
    ISO: "UY / URY",
  },
  {
    country: "Uzbekistan",
    code: "+998",
    ISO: "UZ / UZB",
  },
  {
    country: "Vanuatu",
    code: "+678",
    ISO: "VU / VUT",
  },
  {
    country: "Vatican",
    code: "+379",
    ISO: "VA / VAT",
  },
  {
    country: "Venezuela",
    code: "+58",
    ISO: "VE / VEN",
  },
  {
    country: "Vietnam",
    code: "+84",
    ISO: "VN / VNM",
  },
  {
    country: "Wallis and Futuna",
    code: "+681",
    ISO: "WF / WLF",
  },
  {
    country: "Western Sahara",
    code: "+212",
    ISO: "EH / ESH",
  },
  {
    country: "Yemen",
    code: "+967",
    ISO: "YE / YEM",
  },
  {
    country: "Zambia",
    code: "+260",
    ISO: "ZM / ZMB",
  },
  {
    country: "Zimbabwe",
    code: "+263",
    ISO: "ZW / ZWE",
  },
];
