import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { endpoint } from "./lib";

import sms from "./assets/sms.gif";

const SMS_APP = () => {
  const [flag, setFlag] = useState("");
  const [jwt, setJWT] = useState("");
  const [account, setAccount] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const xAppToken = searchParams.get("xAppToken");

  useEffect(() => {
    (async () => {
      /* Start */
      const user_info = await fetch(`${endpoint}?flag=1&ott=` + xAppToken);
      const response = JSON.parse(await user_info.json());

      if (
        response.ott.accountaccess !== "FULL" ||
        response.ott.nodetype !== "MAINNET"
      ) {
        setFlag(1);
        return;
      }

      setJWT(response.jwt);
      setAccount(response.ott.account_info.account);
      setName(response.ott.account_info.name);
      /* End */

      const user = await fetch(
        `${endpoint}?flag=2&data=` + response.ott.account_info.account
      );

      const response1 = await user.json();

      if (response1 === 0) {
        navigate(
          `/register/${response.jwt}/${response.ott.account_info.account}/${response.ott.account_info.name}`
        );
        //history.push(`/register/${jwt}/${account}/${name}`);
        setFlag(0);
      } else if (response1 === 1) {
        navigate(`/sms/${response.jwt}/${response.ott.account_info.account}`);
        //history.push(`/sms/${jwt}/${account}`);
        setFlag(2); // to bypass 1 for testnet, 0 for register and anything else indicates you are registered
      }
    })();
  }, [xAppToken]);

  return (
    <div style={{ padding: "10px" }}>
      {flag === "" ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            WebkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={sms} alt="Shipping" width="280" height="150" />
        </div>
      ) : flag === 1 ? (
        <div
          style={{
            position: "absolute",
            left: "40%",
            top: "50%",
            WebkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",
          }}
        >
          Switch To MAINNET & Full Access Account.
        </div>
      ) : flag === 0 && jwt !== "" && name !== "" && account !== "" ? (
        <></>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SMS_APP;
