export const sendCommandtoXumm = (command) => {
  if (typeof window.ReactNativeWebView === "undefined") {
    alert(window.ReactNativeWebView);
    throw new Error("This is not a react native webview");
  }
  window.ReactNativeWebView.postMessage(JSON.stringify(command));
};

export const openSignRequest = (uuid) => {
  try {
    sendCommandtoXumm({
      command: "openSignRequest",
      uuid: uuid,
    });
  } catch (e) {
    throw e;
  }
};

export const endpoint =
  "https://script.google.com/a/technotip.org/macros/s/AKfycbzyZvlYXl5g4YyhFyzobnfrGjsGqV3B7qabW6zMxg/exec";
